import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b2a8ccf6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "-cmsd-image" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "-cmsd-content" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "-cmsd-title" }
const _hoisted_8 = { class: "-cmsd-subtitle" }
const _hoisted_9 = {
  key: 1,
  class: "-cmsd-text"
}
const _hoisted_10 = {
  key: 2,
  class: "-cmsd-show-more"
}
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("a", {
      class: _normalizeClass(["ui-landing-page-card", _ctx.cardClasses]),
      href: _ctx.computedHref,
      ref: "component",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, [
      _createElementVNode("span", _hoisted_2, [
        _createElementVNode("div", {
          ref: "imagePlaceholderComponent",
          innerHTML: _ctx.imagePlaceholder.render(`0% 0%, 100% 0%, 100% 100%, 0% 100%`)
        }, null, 8 /* PROPS */, _hoisted_3),
        _createElementVNode("img", {
          src: _ctx.imageSrc,
          onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onImageLoad && _ctx.onImageLoad(...args)))
        }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4)
      ]),
      _createElementVNode("span", _hoisted_5, [
        (_ctx.isAlignEnd)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, " "))
          : _createCommentVNode("v-if", true),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.computedTitle), 1 /* TEXT */),
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.computedSubtitle), 1 /* TEXT */),
        (_ctx.cardOptions?.cardText?.length > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.cardOptions?.cardText ?? ''), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.cardOptions?.showMoreLink)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.cardOptions.buttonText ?? 'Zobacz więcej'), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ])
    ], 10 /* CLASS, PROPS */, _hoisted_1),
    (_ctx.placeholder && !_ctx.placeholder.componentLoaded.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: _ctx.placeholder.render(`0% 0%, 100% 0%, 100% 100%, 0% 100%`)
        }, null, 8 /* PROPS */, _hoisted_11))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}