import { LandingPageElementClickEvent, LandingPageEventNames } from '@/ui/types/landingPage';


export default function handleLandingPageEvent (
  elementName: string,
  pageLayoutInternalName: string,
  imageUrl?: string|undefined,
  productName?: string|undefined,
  productId?: number|undefined,
  event = LandingPageEventNames.Cta,
): void {
  window.dataLayer.push({
    landingPageEventData: null
  });
  window.pushDataLayer({
    event: event,
    landingPageEventData: {
      productName: productName,
      section: pageLayoutInternalName,
      element: elementName,
      productId: productId,
      imageUrl: imageUrl,
    } as LandingPageElementClickEvent
  });
}
