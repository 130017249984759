
import {computed, defineComponent, onMounted, Ref, ref} from 'vue';
import {SwiperContainer} from 'swiper11/swiper-element';
import useUIKit from '@/ui/composable/useUIKit';
import {SwiperOptions} from 'swiper11/types/swiper-options';
import usePlaceholder from '@/ui/composable/usePlaceholder';
import {
  CmsComponent,
  CmsComponentTypeEnum,
  ComponentElement,
  LandingPageSwiperOptions,
  LayoutSlotEnum,
} from '@/api/generated';
import { getImageUrlFromIdOrAbsoluteUrl } from '@/js/utils/assets';
import relativePathIfInternal from '@/ui/utils/relativePathIfInternal';
import handleLandingPageEvent from '@/ui/utils/dataLayer/landingPageEvent';
import { UILandingPageElementClicked } from './types';
import useWebcomponent from '@/ui/composable/useWebcomponent';
import CaretRight from '@/ui-kit/icons/caret-right.svg';
import CaretLeft from '@/ui-kit/icons/caret-left.svg';
import {addClickEventsToSlider, addViewEventsToSlider} from '@/js/swiper/swiper-events-generator';
import {boolean} from 'zod';
export default defineComponent({
  name: 'UILandingPageSwiper',
  props: {
    elements: {type: Array<CmsComponent>||String, default: []},
    landingPageId: {type: String},
    pageLayoutInternalName: {type: String||undefined, default: undefined},
    swiperOptions: {type: String, default: '{}'},
    lazy: {type: Boolean, default: false},
    promotionEvents: {type: Boolean, default: false},
    swiperId: {type: String, required: true},
    layoutSlot: {type: LayoutSlotEnum, required: true}
  },
  setup (props) {
    const landingSwiperOptions: Ref<LandingPageSwiperOptions> = ref({});
    const swiperOptions: Ref<SwiperOptions> = ref({});
    const elementsPayload: Ref<Array<CmsComponent>> = ref([]);
    const component = ref<HTMLElement>();
    const placeholder = usePlaceholder();
    const navigationOptions: Ref<{navigation: boolean|{prevEl: string|HTMLElement, nextEl: string|HTMLElement, enabled: true}}> = ref({navigation: false});
    const swiperOptionsWithDefaults = computed((): SwiperOptions => {
      const defaults = {
        breakpoints: {
          0: {
            slidePerView: 1,
          },
        },
        spaceBetween: 32,
        slidesOffsetBefore: 0,
        injectStylesUrls: ['assets/ui-kit/ui-swiper.css'],
      } as SwiperOptions
      return {
        ...defaults,
        ...swiperOptions.value,
        ...navigationOptions.value,
      }
    })

    const onElementClicked = (element: CmsComponent|ComponentElement) => {
      let elementName: string = '';
      let imageUrl: string|undefined = undefined;
      if (element.options && typeof element?.options?.imageOptions !== 'undefined') {
        elementName = String(element?.options?.imageOptions?.imageAlt) ?? '';
        imageUrl = element.options?.imageOptions?.imageSrc !== null ?
          getImageUrlFromIdOrAbsoluteUrl(String(element.options?.imageOptions?.imageSrc)):
          undefined
      }
      if (element?.options?.textOptions?.content) {
        elementName = getImageUrlFromIdOrAbsoluteUrl(
          String(element?.options?.textOptions?.content),
        ) ?? '';
        imageUrl = undefined;
      }

      handleLandingPageEvent(
        elementName,
        props.pageLayoutInternalName ?? '',
        imageUrl,
      );
    }

    onMounted(async () => {
      landingSwiperOptions.value = JSON.parse(props.swiperOptions) as
        unknown as LandingPageSwiperOptions;
      if (landingSwiperOptions.value.swiperNavigationShown) {
        navigationOptions.value = {
          navigation: {
            enabled: true,
            prevEl: component.value?.querySelector('.swiper-button-prev') as HTMLElement,
            nextEl: component.value?.querySelector('.swiper-button-next')as HTMLElement,
          },
        }
      }
      if (landingSwiperOptions.value.spaceBetween === null) {
        swiperOptions.value.spaceBetween = 0;
      }
      swiperOptions.value.loop = landingSwiperOptions.value.loop ?? false;
      swiperOptions.value.slidesPerView = landingSwiperOptions.value.slidesPerView ?? 1;
      swiperOptions.value.autoplay = landingSwiperOptions.value.autoplay ?? false;

      elementsPayload.value = (typeof props.elements == 'string' ?
      JSON.parse(props.elements) as unknown as Array<CmsComponent> :
        props.elements
      );
      await useWebcomponent('ui-swiper', false);
      const { markAsLoaded } = await useUIKit(
        component,
        'ui-landing-page-swiper',
        {
          usedExternal: false,
          loadImmediately: false,
        },
      );
      markAsLoaded();
      await useUIKit(component, 'ui-landing-page-swiper');

      const swiperEl = component.value?.querySelector(
        'swiper-container',
      ) as unknown as SwiperContainer;
      Object.assign(swiperEl, {
        ...swiperOptionsWithDefaults.value,
        ...{
          autoplay: (swiperOptionsWithDefaults.value.autoplay ? {delay: 3000} : false),
        },
        on: {
          afterInit () {
            placeholder.init(component);
            addViewEventsToSlider(swiperEl, this, 'view_slider_element', 'banner-landing', props.layoutSlot, props.promotionEvents);
            addClickEventsToSlider(swiperEl, this, 'click_slider_element', 'banner-landing', props.layoutSlot, props.promotionEvents);
          },
        },
      },
      );
      swiperEl?.initialize();
    })

    return {
      component,
      placeholder,
      swiperOptionsWithDefaults,
      props,
      CmsComponentTypeEnum,
      elementsPayload,
      getImageUrlFromIdOrAbsoluteUrl,
      relativePathIfInternal,
      onElementClicked,
      CaretLeft,
      CaretRight,
    }
  },
})
