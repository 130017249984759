import {defineCustomElement} from 'vue';
import UIIframe from '@/ui-kit/js/components/ui-iframe/UIIframe.vue';
import '@/ui-kit/js/components/ui-landing-page-swiper/index';
import '@/ui-kit/js/components/ui-landing-page-card/index';
import {LandingPageElementClickEvent, LandingPageEventNames} from '@/ui/types/landingPage';
import promotion from '@/ui/utils/dataLayer/promotion';
import isInViewport from '@/js/common/isInViewport';

customElements.define('ui-iframe', defineCustomElement(UIIframe));
const viewedElements: string[] = [];
const {selectPromotion, viewPromotion} = promotion();
document.addEventListener('scroll', () => {
	document.querySelectorAll('.cms-component-image a').forEach((el) => {
		const element = el as HTMLLinkElement;
		if (element.parentElement?.classList.contains('cms-component--12col') || element.parentElement?.classList.contains('layout-slot-footer')) { // @TODO Move to data with promotion id assignment when done in panel
			if (isInViewport(element) && !viewedElements.includes(element.parentElement?.id?.toLowerCase() ?? '')) {
				viewPromotion({
					promotion_id: element.dataset.promotionId?.toLowerCase() ?? '',
					promotion_name: element.dataset.promotionName?.toLowerCase() ?? '',
					promotion_type: 'banner-landing',
					promotion_placement: element.parentElement?.classList.contains('layout-slot-footer') ? 'footer' : 'content',
					element: element.getAttribute('title') ?? element.getAttribute('alt') ?? element.parentElement?.id ?? '',
				})
				viewedElements.push(element.parentElement?.id?.toLowerCase() ?? '');
			}
		}
	});
});


document.querySelectorAll('.cms-component-image a').forEach((el) => {
	(el as HTMLImageElement).addEventListener('click', () => {
		const element = el as HTMLLinkElement;
		const imgElement = element?.querySelector('img') as HTMLImageElement;
		if (element.parentElement?.classList.contains('cms-component--12col') || element.parentElement?.classList.contains('layout-slot-footer')) { // @TODO Move to data with promotion id assignment when done in panel
			selectPromotion(
				{
					promotion_id: element.dataset.promotionId?.toLowerCase() ?? '',
					promotion_name: element.dataset.promotionName?.toLowerCase() ?? '',
					promotion_type: 'banner-landing',
					promotion_placement: element.parentElement?.classList.contains('layout-slot-footer') ? 'footer' : 'content',
					element: element.getAttribute('title') ?? imgElement.getAttribute('alt') ?? element.parentElement?.id ?? '',
				}
			);
		}

		window.pushDataLayer({
			landingPageEventData: null,
		})
		window.pushDataLayer({
			event: LandingPageEventNames.Cta,
			landingPageEventData: {
				element: imgElement.alt ?? element.title ?? '',
				section: `${imgElement.dataset?.landingPageLayoutName}` ?? '',
				imageUrl: imgElement?.src ?? '',
			} as LandingPageElementClickEvent,
		})
	});
})

document.querySelectorAll('.heading-link').forEach((el) => {
	(el as HTMLLinkElement).addEventListener('click', () => {
		const element = el as HTMLLinkElement;
		window.pushDataLayer({
			ecommerce: null,
		})
		window.pushDataLayer({
			event: LandingPageEventNames.Cta,
			landingPageEventData: {
				element: `Nagłówek ${element?.tagName}: ${element?.innerText}` ?? '',
				section: `${element?.dataset?.landingPageLayoutName}` ?? '',
			} as LandingPageElementClickEvent,
		})
	});
})
document.querySelectorAll('.heading-see-more').forEach((el) => {
	(el as HTMLLinkElement).addEventListener('click', (ev) => {
		const element = el as HTMLLinkElement;
		window.pushDataLayer({
			landingPageEventData: null,
		})
		window.pushDataLayer({
			event: LandingPageEventNames.Cta,
			landingPageEventData: {
				element: `Nagłówek ${element?.title}` ?? '',
				section: element?.dataset?.landingPageLayoutName ?? '',
			} as LandingPageElementClickEvent,
		})
	});
})
