
import {defineComponent, ref, computed, watch, onMounted, PropType, Ref} from 'vue';
import { getImageThumb } from '@/js/utils/assets';
import { ImageThumbsEnum } from '@/js/typings/imageThumbs';
import relativePathIfInternal from '@/ui/utils/relativePathIfInternal';
import {CardOptions} from '@/api/generated';
import {
  LandingPageElementClickEvent,
  LandingPageEventNames,
} from '@/ui/types/landingPage';
import {FlexAlignEnum, FlexDirectionEnum} from '@/js/components/landing-page-dynamic-components/types';
import {frontApi} from '@/api/useFrontendApi';
import useUIKit from '@/ui/composable/useUIKit';
import usePlaceholder from '@/ui/composable/usePlaceholder';
import item from '@/ui/utils/dataLayer/item';
export default defineComponent({
  name: 'UILandingPageCard',
  props: {
    landingLayoutName: {
      type: String,
      default: undefined,
      required: false,
    },
    url: {
      type: String,
      required: false,
      default: undefined,
    },
    cardOptionsPayload: {
      type: String||Object as () => PropType<CardOptions>,
      required: true,
    },
  },
  setup(props) {
    const component = ref();
    const cardOptions: Ref<CardOptions | undefined> = ref();
    const imageSrc = ref('');
    const imagePlaceholderComponent = ref();
    const placeholder = usePlaceholder();
    const imagePlaceholder = usePlaceholder();
    const productId = ref<number|undefined>();

    const cardClasses = computed(() => {
      const modificators: string[] = [];
      if (!cardOptions.value) {
        return 'ui-landing-page-card-card';
      }

      if (cardOptions.value?.flexDirection === FlexDirectionEnum.ColumnReverse) modificators.push('-cmsd--img-bottom');
      if (cardOptions.value?.flexDirection === FlexDirectionEnum.RowReverse) modificators.push('-cmsd--img-right');
      if (cardOptions.value?.flexDirection === FlexDirectionEnum.Row) modificators.push('-cmsd--img-left');

      if (cardOptions.value?.alignItems) modificators.push(`-cmsd--align-items-${cardOptions.value.alignItems}`);
      if (cardOptions.value?.justifyContent) modificators.push(`-cmsd--justify-content-${cardOptions.value.justifyContent}`);

      return ['ui-landing-page-card-card', ...modificators].filter(item => item).join(' ');
    });

    const isAlignEnd = computed(() => cardOptions.value?.alignItems === FlexAlignEnum.End);
    const state = ref({
      name: '',
      imageUrl: undefined,
      authors: [{
        name: ''
      }],
    });

    const computedTitle = computed(() => state.value.name ? state.value.name : cardOptions.value?.cardTitle);
    const computedSubtitle = computed(() => state.value.authors[0]?.name ? state.value.authors[0]?.name : cardOptions.value?.cardSubtitle);
    const computedHref = computed(() => relativePathIfInternal(cardOptions.value?.cardLink || decodeURIComponent(window.atob(props.url ?? ''))) || '#');

    const onImageLoad = (event: Event) => {
      const target = event.target as HTMLImageElement;
      target.style.height = `${target.getBoundingClientRect().height}px`;
      imagePlaceholderComponent.value.remove();
    };

    const fetchImage = () => {
      const src = (state.value?.imageUrl && state.value?.imageUrl.length > 0 ? state.value?.imageUrl : null) ?? getImageThumb(cardOptions.value?.cardImage?.imageUrl as string, ImageThumbsEnum.Small);
      const preload = new Image();
      preload.src = src;
      preload.onload = () => {
        imageSrc.value = src;
      };
    };

    const handleClick = () => {
      window.pushDataLayer({
        event: LandingPageEventNames.Cta,
        landingPageEventData: {
          imageUrl: imageSrc.value,
          section: props.landingLayoutName ?? '',
          element: `Karta: ${computedTitle.value}`,
          productId: productId.value,
          productName: computedTitle.value,
        } as LandingPageElementClickEvent,
      })
    }

    onMounted(async () => {
      const { markAsLoaded } = await useUIKit(
        component,
        'ui-landing-page-card',
        {
          usedExternal: false,
          loadImmediately: false,
        },
      );
      markAsLoaded();
      placeholder.init(component);
      imagePlaceholder.init(imagePlaceholderComponent);
      if (typeof props.cardOptionsPayload === 'string') {
        cardOptions.value = JSON.parse(props.cardOptionsPayload);
      } else {
        cardOptions.value = props.cardOptionsPayload;
      }

      if (props.url) {
        let link: string = decodeURIComponent(window.atob(props.url ?? '')) ?? '';
        const hasQueryParameters = link.includes('?');
        const separator = hasQueryParameters ? '&' : '?';
        const limit = 1;
        if (link) {
          if (limit) {
            link = link + `${separator}showPerPage=${limit}`;
          } else if (!link.includes('showPerPage')) {
            link = link + `${separator}showPerPage=36`;
          }
          link += '&hasImage=1';
          link = window.btoa(encodeURIComponent(link));
        }

        const res = await frontApi.landingPage.searchProductsByUrls({urlArray: [link]});
        if (res[link]) {
          state.value.imageUrl = res[link].products[0].imageUrl ?? '';
          imageSrc.value = state.value.imageUrl;
          state.value.name = res[link].products[0].name;
          state.value.authors = res[link].products[0].authors;
          productId.value = res[link].products[0].productId;
        }
      }

      fetchImage();
    });

    return {
      component,
      imageSrc,
      cardClasses,
      isAlignEnd,
      computedTitle,
      computedSubtitle,
      computedHref,
      onImageLoad,
      handleClick,
      FlexDirectionEnum,
      cardOptions,
      state,
      placeholder,
      imagePlaceholder,
      imagePlaceholderComponent,
    };
  },
});
